import { mdiFileExcelOutline, mdiPlus } from "@mdi/js";
import { Button, Space } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import { Permission } from "components/Permission";
import { Role } from "models/common";
import React from "react";
import { useTranslation } from "react-i18next";

interface TableHeaderOptionsProps {
  onNewButtonClick: React.MouseEventHandler<HTMLElement> | undefined;
  onExportButtonClick: React.MouseEventHandler<HTMLElement> | undefined;
  exportLoading?: boolean;
  addNewRoles?: Role[];
  exportRoles?: Role[];
  addNewClaims?: string[];
}

const TableHeaderOptions: React.FC<TableHeaderOptionsProps> = ({
  onNewButtonClick,
  onExportButtonClick,
  exportLoading,
  addNewRoles = [],
  exportRoles = [],
  addNewClaims = [],
}) => {
  const { t } = useTranslation();

  return (
    <Space>
      <Permission acceptableRoles={addNewRoles} acceptableClaims={addNewClaims}>
        <Button
          icon={<MaterialIcon path={mdiPlus} />}
          type="primary"
          onClick={onNewButtonClick}
        >
          {t("common.new")}
        </Button>
      </Permission>

      <Permission acceptableRoles={exportRoles} acceptableClaims={[]}>
        <Button
          icon={<MaterialIcon path={mdiFileExcelOutline} />}
          onClick={onExportButtonClick}
          loading={exportLoading}
        >
          {t("common.export")}
        </Button>
      </Permission>
    </Space>
  );
};

export default TableHeaderOptions;
