/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from "react";
import { TablePaginationConfig } from "antd/lib/table";
import { PaginationConfig } from "antd/lib/pagination";
import {
  SorterResult,
  SortOrder,
  ColumnFilterItem,
} from "antd/lib/table/interface";
import { useTranslation } from "react-i18next";
import { ExtendedColumnType } from "./table-settings/ExtendedColumnType";
import { FilterIcon } from "./table-dropdowns/FilterIcon";
import { SearchTableDropdown } from "./table-dropdowns/SearchTableDropdown";
import { EmptyContent } from "./table-content/EmptyContent";
import { DatepickerTableDropdown } from "./table-dropdowns/DatepickerTableDropdown";
import DateDisplay, { DateFormat } from "./DateDisplay";
import { DateRangePickerTableDropdown } from "./table-dropdowns/DateRangePickerTableDropdown";
import { ActivenessDisplay } from "./ActivenessDisplay";
import dayjs from "dayjs";
import { RadioTableDropdown } from "./table-dropdowns/RadioTableDropdown";
import { SelectTableDropdown } from "./table-dropdowns/SelectTableDropdown";

export enum OrderByType {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum FilterMode {
  FILTER = "filter",
  FILTER_SELECT = "filter_select",
  SEARCH = "search",
  DATEPICKER = "datepicker",
  DATERANGEPICKER = "daterangepicker",
  /**
   * For filtering and renderindg YES or NO values, mapped to 'true' and 'fasle'.
   * No need to populate filters and render prop with this option.
   */
  YES_NO = "yes_no",
  /**
   * For filtering and rendering ACTIVE or INACTIVE values, mapped to 'true' and 'fasle'.
   * No need to populate filters and render prop with this option.
   */
  ACTIVE_INACTIVE = "active_inactive",
  ENUM = "enum",
}

export enum DataType {
  STRING = "string",
  DATE = "search",
  YES_NO = "yes_no",
  ACTIVE_INACTIVE = "active_inactive",
}

export interface Pagination {
  page?: number;
  from?: number;
  to?: number;
  size?: number;
  pageSize?: number;
}

export interface Order {
  orderBy: string;
  orderByType?: OrderByType;
}
export interface ListRequestParams extends Pagination {
  filter?: { [filterKey: string]: any };
  totalCount?: number;
  order?: Order[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line no-unused-vars
export interface TableUtilsProps<T> {
  listParamsState?: ListRequestParams;
  sortWithoutDefaultOption?: boolean;
  getDataFunc?: (params: ListRequestParams) => Promise<any>;
  /** Config for the columns that contain data */
  columnParams: ColumnConfigParams[];
  /** Config for the column that contains the edit, view or delete buttons */
  actionColumnParams?: Partial<ExtendedColumnType<T>>;
  allowMultiSort?: boolean;
}

export const mergeListParams = (
  oldParams: ListRequestParams,
  newParams: ListRequestParams
): ListRequestParams => ({
  ...oldParams,
  ...newParams,
});

export const mergeListParamsWithPagination = (
  revisedParams: ListRequestParams,
  responsePagination: Pagination
): ListRequestParams => ({
  ...revisedParams,
  ...responsePagination,
});

export interface ActivenessOptions {
  active: string;
  inactive: string;
  deleted?: string;
}

/**
 * Configures the Ant Design table
 */
export interface ColumnConfigParams<T = any>
  extends Omit<ExtendedColumnType<T>, "filterMode"> {
  /** The property name of the item */
  key: string;
  /** The way that the column can be filtered */
  filterMode?: FilterMode;
  /**
   * Optional, overwrites the default filters if present. These filters are the values that the
   * table can be filtered for.
   */
  filters?: ColumnFilterItem[];
  /** Enables sorting the table by this column's value */
  sort?: boolean;
  /**
   * If this parameter has the value "date time", the column will be rendered with
   * `DateDisplay`
   */
  dateFormat?: DateFormat;
  /** If filter options are coming from backend and data is not available yet */
  filterOptionsLoading?: boolean;
  /** Property to display the data correctly in config.render */
  dataType?: DataType;
}

export interface TableUtils<T> {
  paginationConfig: false | TablePaginationConfig;
  handleTableChange: any;
  columnsConfig: ExtendedColumnType<T>[];
  addKeyProp: (data?: T[]) => T[];
}

/** Combine the old and new pagination values. */
const calculatePagination = (
  newPagination: Pagination,
  oldPagination: Pagination
): Pagination => ({
  ...oldPagination,
  ...newPagination,
  page: Math.max(newPagination?.page ?? oldPagination?.page ?? 1, 1),
});

/** When the last item is deleted on the table page, the pagination page must be reduced. */
const recalculatePaginationAfterDeletion = (
  oldPagination?: Pagination
): number => {
  if (oldPagination?.page === undefined) {
    return 1;
  }
  const reductPage = oldPagination.to === oldPagination.from;
  const page = oldPagination.page - +reductPage;
  return Math.max(page, 1);
};

/** When the page size is changed, this will keep the previous top element on the new (resized) page. */
const projectPageToNewPageSize = (
  newSize: number,
  pagination?: Pagination
): number => {
  return typeof pagination?.from === "number"
    ? Math.ceil(pagination.from / newSize)
    : 1;
};

export const commonPaginationConfig: TablePaginationConfig = {
  pageSizeOptions: ["5", "10", "25", "50"],
  showSizeChanger: true,
  size: "default",
  position: ["bottomRight"],
};

/** The common pagination config. */
export const basePaginationConfig = (
  pagination?: ListRequestParams
): TablePaginationConfig => ({
  pageSize: pagination?.pageSize ?? 10,
  current: pagination?.page ?? 1,
  total: pagination?.totalCount ?? 0,
  ...commonPaginationConfig,
});

/**
 * Used for implementating tables with pagination, filtering, searching and sorting.
 @example
 // For filtering with dropdown select

 */
function useTableUtils<T extends { [key: string]: any }>({
  listParamsState,
  getDataFunc,
  sortWithoutDefaultOption,
  actionColumnParams,
  columnParams,
  allowMultiSort,
}: TableUtilsProps<T>): TableUtils<T> {
  const { t } = useTranslation();

  const addKeyProp = (data?: T[]): T[] =>
    data?.map((t, i) => ({ ...t, key: "" + i + t?.id })) ?? [];

  const paginationConfig = useMemo((): TablePaginationConfig | false => {
    const baseConfig = basePaginationConfig(listParamsState);
    return baseConfig.total ? baseConfig : false;
  }, [listParamsState]);

  const toSortOrder = useCallback(
    (orderType?: OrderByType | null): SortOrder | undefined => {
      switch (orderType) {
        case OrderByType.Ascending:
          return "ascend";
        case OrderByType.Descending:
          return "descend";
        default:
          return undefined;
      }
    },
    []
  );

  const toOrderByType = useCallback(
    (sortOrder?: SortOrder): OrderByType | undefined => {
      switch (sortOrder) {
        case "ascend":
          return OrderByType.Ascending;
        case "descend":
          return OrderByType.Descending;
        default:
          return undefined;
      }
    },
    []
  );

  const columnsConfig = useMemo((): ExtendedColumnType<any>[] => {
    const columnsConfig = columnParams.map((params, columnIndex) => {
      const {
        key,
        filterMode,
        sort,
        filters,
        dateFormat,
        dataIndex,
        filterOptionsLoading,
        dataType,
        ...rest
      } = params;

      const config: ExtendedColumnType<T> = {
        ellipsis: true,
        ...rest,
        dataIndex: dataIndex || key,
        key,
      };

      // TODO: kisebb functionökre bontás

      // Filtering
      switch (filterMode) {
        case FilterMode.SEARCH:
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          config.filterDropdown = SearchTableDropdown;
          config.filterMultiple = false;
          break;
        case FilterMode.DATEPICKER:
          config.filterDropdown = DatepickerTableDropdown;
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="date" />
          );
          config.filterMultiple = false;
          break;
        case FilterMode.DATERANGEPICKER:
          config.filterDropdown = DateRangePickerTableDropdown;
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="date" />
          );
          config.filterMultiple = false;
          break;
        case FilterMode.FILTER:
          config.filterDropdown = (props) => (
            <RadioTableDropdown {...props} loading={filterOptionsLoading} />
          );
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          config.filterMultiple = false;
          config.filters = filters;
          break;
        case FilterMode.FILTER_SELECT:
          config.filterDropdown = (props) => (
            <SelectTableDropdown {...props} loading={filterOptionsLoading} />
          );
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          config.filterMultiple = false;
          config.filters = filters;
          break;
        case FilterMode.YES_NO:
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          config.filterMultiple = false;
          config.filters = filters ?? [
            { value: "true", text: t("common.yes") },
            { value: "false", text: t("common.no") },
          ];
          break;
        case FilterMode.ACTIVE_INACTIVE: {
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          const activeText = t("common.active");
          const inactiveText = t("common.inactive");

          config.filterMultiple = false;
          config.filters = [
            { value: true, text: activeText },
            { value: false, text: inactiveText },
          ];
          break;
        }

        // TODO: KELL EZ EGYÁLTALÁN? FilterMode.FILTER ugyanez kb, csak ott config.filterMultiple = false;
        case FilterMode.ENUM:
          config.filterIcon = (filtered) => (
            <FilterIcon isFiltered={filtered} type="filter" />
          );
          config.filters = filters;
          break;
        default:
          break;
      }

      // Data display
      if (!config.render) {
        switch (dataType) {
          case DataType.DATE:
            config.render = (value: any) =>
              value ? (
                <DateDisplay value={value} format={dateFormat} />
              ) : (
                <EmptyContent />
              );
            break;

          case DataType.YES_NO:
            config.render = (value: boolean) =>
              value ? t("common.yes") : t("common.no");
            break;

          case DataType.ACTIVE_INACTIVE: {
            const activeText = t("common.active");
            const inactiveText = t("common.inactive");

            config.render = (value) => (
              <ActivenessDisplay
                isActive={value}
                text={value ? activeText : inactiveText}
              />
            );
            break;
          }

          default:
            break;
        }
      }

      // Sorting
      if (sort && listParamsState) {
        const orderDataForColumn = listParamsState.order?.find(
          (order) => order.orderBy === key
        );

        config.sortOrder = orderDataForColumn
          ? toSortOrder(orderDataForColumn.orderByType)
          : undefined;

        if (allowMultiSort) {
          config.sorter = {
            multiple: columnIndex + 1,
          };
        } else {
          config.sorter = true;
        }
      }

      if (!config.render) {
        config.render = (value: any) =>
          value && value !== "" ? value : <EmptyContent />;
      }

      // Filtering
      if (filterMode && listParamsState) {
        const value = listParamsState?.filter?.[key];

        // The default value for filterMultiple is true in ant design
        if (config.filterMultiple === undefined || config.filterMultiple) {
          config.filteredValue = value;
        } else {
          config.filteredValue = value !== undefined ? [value] : [];
        }
      }

      return config;
    });

    if (actionColumnParams) {
      columnsConfig.push({
        key: "actions",
        colSpan: 1,
        ...actionColumnParams,
      });
    }

    return columnsConfig;
  }, [
    columnParams,
    actionColumnParams,
    listParamsState,
    t,
    toSortOrder,
    allowMultiSort,
  ]);

  /**
   * `onChange` handler for Ant Design Table
   * This callback executed when pagination, filters or sorter is changed
   */
  const handleTableChange: any = useCallback(
    (
      pagination: PaginationConfig,
      filters: Record<string, React.ReactText[] | null>,
      sorter: SorterResult<any>
    ) => {
      // In case of no sort use 'ascend'. So instead of 'ascend' -> 'descent' -> undefined -> 'ascend'
      // it will be 'ascend' -> 'descent' -> 'ascend'
      if (sortWithoutDefaultOption && !sorter.order) sorter.order = "ascend";

      const correctedPagination = calculatePagination(
        {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        listParamsState! /* TODO: REMOVE NON-NULL ASSERTION */
      );

      const requestParams: ListRequestParams = {
        page: correctedPagination.page,
        pageSize: correctedPagination.pageSize,
      };

      if (
        pagination.pageSize &&
        listParamsState?.pageSize !== pagination.pageSize
      ) {
        requestParams.page = projectPageToNewPageSize(
          pagination.pageSize,
          listParamsState
        );
      }

      // Sorting
      if (!allowMultiSort) {
        const orderByType = toOrderByType(sorter.order);

        requestParams.order = [
          ...(orderByType
            ? [
                {
                  orderByType,
                  orderBy: sorter.columnKey as string,
                },
              ]
            : []),
        ];
      } else {
        // If multiple columns have active sorting
        if (Array.isArray(sorter)) {
          requestParams.order = sorter.map((soterValue) => ({
            orderByType: toOrderByType(soterValue.order),
            orderBy: soterValue.columnKey,
          }));
        } else {
          const orderByType = toOrderByType(sorter.order);

          requestParams.order = [
            ...(orderByType
              ? [
                  {
                    orderByType,
                    orderBy: sorter.columnKey as string,
                  },
                ]
              : []),
          ];
        }
      }

      requestParams.filter = columnParams.reduce<{ [filterKey: string]: any }>(
        (previousValue, currentValue, currentIndex) => {
          const { key, filterMode } = columnParams[currentIndex];
          const { filterMultiple } = columnsConfig[currentIndex];
          const filterItems = filters?.[key];

          switch (filterMode) {
            case FilterMode.DATEPICKER:
              if (filterItems) {
                const filterItem = filterItems[0] as unknown as dayjs.Dayjs;
                previousValue[key] = filterItem;
              } else {
                previousValue[key] = undefined;
              }

              break;

            case FilterMode.DATERANGEPICKER:
              if (filterItems && filterItems[0]) {
                const filterItem = filterItems[0] as unknown as dayjs.Dayjs[];
                filterItem[0].startOf("day");
                filterItem[1].endOf("day");

                previousValue[key] = filterItem;
                previousValue[key + "From"] = filterItem[0].toISOString();
                previousValue[key + "To"] = filterItem[1].toISOString();
              }

              previousValue[key] = undefined;
              previousValue[key + "From"] = undefined;
              previousValue[key + "To"] = undefined;

              break;

            default: {
              previousValue[key] =
                filterMultiple === undefined || filterMultiple
                  ? filterItems
                  : filterItems?.[0];

              break;
            }
          }

          return previousValue;
        },
        {}
      );

      getDataFunc?.(requestParams);
    },
    [
      sortWithoutDefaultOption,
      listParamsState,
      allowMultiSort,
      getDataFunc,
      toOrderByType,
      columnParams,
      columnsConfig,
    ]
  );

  return {
    paginationConfig,
    handleTableChange,
    columnsConfig,
    addKeyProp,
  };
}

export { useTableUtils, recalculatePaginationAfterDeletion };
