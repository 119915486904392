import { createModel } from "@rematch/core";
import { api } from "api";
import { ContractorVm, FailureVm, RepairmanVm } from "api/generated/lumen";
import { RootModel } from "app/store";
import { getTownNameFiltersForContractor } from "features/failures/utils";

interface FailureBulkAssignState {
  assignableFailures: FailureVm[];
  contractors: ContractorVm[];
  repairmen: RepairmanVm[];
}

const initialState: FailureBulkAssignState = {
  assignableFailures: [],
  contractors: [],
  repairmen: [],
};

export const failureBulkAssign = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAssignableFailures(state, data: FailureVm[]) {
      state.assignableFailures = data;
    },
    setContractors(state, data: ContractorVm[]) {
      state.contractors = data;
    },
    setRepairmen(state, data: RepairmanVm[]) {
      state.repairmen = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { failureBulkAssign } = dispatch;

    return {
      async fetchContractorsForTowns(townNames: string[]): Promise<void> {
        const townNameFilters = getTownNameFiltersForContractor(townNames);

        const data = await api.lumen.contractors.apiContractorsGetAllPost({
          getAllContractorsQuery: {
            pageSize: -1,
            filterKeys: {
              isDeleted: false,
              ...townNameFilters,
            },
          },
        });

        const { items } = data;

        dispatch(failureBulkAssign.setContractors(items || []));
      },

      async fetchContractorsForDevices(deviceIds: string[]): Promise<void> {
        const data =
          await api.lumen.contractors.apiContractorsGetContractorsBasedOnDeviceIdsPost(
            {
              getContractorsBasedOnDeviceIdsQuery: {
                deviceIds,
              },
            }
          );

        dispatch(failureBulkAssign.setContractors(data));
      },

      async fetchRepairmen(contractorId: string): Promise<void> {
        const data = await api.lumen.repairmen.apiRepairmenGetAllPost({
          getAllRepairmenQuery: {
            pageSize: -1,
            filterKeys: {
              isActive: "true",
              "contractor.id": contractorId,
            },
          },
        });

        const { items } = data;

        dispatch(failureBulkAssign.setRepairmen(items || []));
      },

      async assignFailuresToContractor({
        failureIds,
        contractorId,
      }: {
        failureIds: string[];
        contractorId: string;
      }): Promise<void> {
        if (contractorId) {
          return api.lumen.failures.apiFailuresAssignMultipleToContractorPut({
            assignFailuresToContractorCommand: {
              contractorId,
              failureIds,
            },
          });
        }
      },

      async assignFailuresToRepairmen({
        failureIds,
        repairmanIds,
      }: {
        failureIds: string[];
        repairmanIds: string[];
      }): Promise<void> {
        if (repairmanIds.length) {
          return api.lumen.failures.apiFailuresAssignManyToRepairmanPut({
            assignFailuresToRepairmanCommand: {
              failureIds,
              repairmanIds,
            },
          });
        }
      },
    };
  },
});
