export enum ColumnStorageName {
  FAILURES = "failures-columns",
  TOWNS = "towns-columns",
  FAILURE_TYPES = "failure-types-columns",
  INVENTORY = "inventory",
  CONTRACTORS = "contractors",
  REPAIRMEN = "repairmen",
  REPAIRMEN_BY_CONTRACTOR = "repairmen-by-contractor",
  INVENTORY_CATEGORY = "inventory-category",
  FAILURE_TASK = "failure-task",
  MAINTAINED_DEVICES = "maintained-devices-list",
  SPECIAL_PLACES = "special-places",
  EXAM_TYPES = "exam-types",
  DUMMY = "dummy",
}
